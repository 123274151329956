  @charset "utf-8";

  .typechild:hover {
      border-color: #6e98ed;
  }

  .hp_typeC {
      display: flex;
      align-items: center;
      font-size: 15px;
      cursor: pointer;
      border-radius: 17px;
      border: 1px solid #eee;
      padding: 3px 14px;
      color: rgb(141, 141, 141);
      box-sizing: border-box;
      white-space: nowrap;
  }


  .divOw::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 6px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  .divOw::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.1);
  }

  /*定义滑块 内阴影+圆角*/
  .divOw::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: rgba(0, 0, 0, 0.1);
  }

  .hp_typeC+.hp_typeC {
      margin-left: 10px;
  }

  .hp_typeC span:nth-child(1) {
      margin: 0 5px 0 0;
  }


  .hp_typeC:hover {
      border-color: #6e98ed;
      color: #5e89dd;
  }