body{
    margin: 0;
    padding: 0;
    /* background-color: #f6f6f6; */
    width: 100%;
}
/* 頭部 */
.yl-head {
background-color: white;
height: 40px;
width: 100%;
font-size: 20px;
line-height: 40px;
text-align: center;
}   
.yl-head span:nth-child(1) {
float: left;
}
.yl-head img {
height: 20px;
margin: 10px 5px;
}

.yl-head span:nth-child(2) {
display: inline-block;
}
/* 内容 */
.tj-name{
background-color: white;
height: 50px;
line-height: 50px;
margin-top: 10px;
width: 100%;
padding: 0 20px;
}
.tj-span{margin-left: 0px;}
.tj-input{
float: right;
margin-top: 16px;
/* color: #909090; */
/* width: 100px; */
text-align: right;
}
.tj-ship{
background-color: white;
height: 70px;
line-height: 70px;
margin-top: 10px;
width: 100%;
}
.tj-ship{
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    position: relative;
}
/* .tj-ship span{margin-left: 20px;} */
.tj-ship div img{width: 25%;float: right;margin: 2px 10px;height: inherit;}
.tj-ship div span {
    padding: 5px 8px;
    background: #29cc89;
    color: #fff;
    border-radius: 6px;
}
.tj-ship div input {
    position: absolute;
    top: 20px;
    right: -235px;
    opacity: 0;
}
.tj-button{background-color: #29cc89;width: 65px;height: 20px;border: none;border-radius: 4px;color: white;font-size: 10px;float: right;display: grid;margin-right: -90px;margin-top: 23px;}
.tj-button1{background-color: #29cc89;width: 75px;height: 25px;border: none;border-radius: 4px;color: white;font-size: 10px;float: right;margin-right: 10px;margin-top: 26px;}
.tj-shuom{
    background-color: white;
    margin-top: 10px;
}
.tj-shuom span{
    margin-left: 20px;
    display: block;
    line-height: 35px;
}
.tj-text{margin: 0px;
width: 99%;
height: 155px;
border-left: none;
border-right: none;
/* color: #a6a6a6; */
border-color: #e7e7e7;
outline: none;
padding-top: 10px;
text-indent: 17px;
margin-bottom: 35px;
}
.tj-bottom{
    position: fixed;
    height: 40px;
    line-height: 40px;
    bottom:0px;
    width:100%;
    text-align: center;
}
.tj-left{width: 40%;background-color: white;float: left;}
.tj-right{width: 60%;background-color: #29cc89;float: left;}