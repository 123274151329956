*{
    box-sizing: border-box !important;
}
.login{
}
a{
    text-decoration: none;
}
.login .clearBoth:after{content:"";display:block;clear:both;zoom: 1;}
.login .contain{
    width: 23%;
    min-width: 400px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 20px #eee;
}
.login .header span{
    font-weight: bold;
    color: #2a97ff;
    font-size: 16px;
}
.login .header span:nth-child(1){
    float: left;
}
.login .header span:nth-child(2){
    float: right;
}
.login .userLogin{
    margin-top: 25px;
    position: relative;
}
.Geetest {
    margin:15px auto;
    /* width: 300px; */
    padding: 0 20px;
}
.login .userLogin img{
    /* width: 50%; */
    display: inline-block;
    width: 23px;
    padding-right: 5px;
    border-right: 1px solid #2a97ff;
    position: absolute;
    top: 13px;
    left: 5px;
}

.login .userLogin input{
    width: 100%;
    padding: 15px 0 15px 30px;
    outline: none;
    border: 1px solid #ccc;
    font-size: 14px;
}
.login .loginUser button{
    width: 100%;
    color: #fff;
    background: #2a97ff;
    padding: 10px;
    border-radius: 30px;
    border: none;
    font-size: 18px;
    text-align: center;
    outline: none;
    cursor: pointer;
}
.login .contain p:nth-child(1){
    text-align: right;
    color: #2a97ff;
}
.login p{
    margin: 15px 0;
    font-size: 12px;
    text-align: center;
}
.login p span{
    color: #2a97ff;
    cursor: pointer;
}
.login .guge{
    text-align: center;
}
.login .guge button{
    width: 100%;
    background: #f26875;
    margin-top: 9px;
    font-size: 18px;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    padding: 10px;
    border-radius: 30px;
    text-align: center;
}

/* 注册 */
.login .selectList{
    background: #fff;
    /* padding: 0 10px; */
    font-size: 14px;
    width: 100%;
    height: 125px;
    overflow-y: scroll;
    position: absolute;
    box-shadow: 2px 2px 5px #ccc;
    color:#555;
    z-index: 10;
}
.login .selectList li {
    padding: 6px;
}
.login .selectList li:hover {
    background: #e6f7ff;
}
.login .identity{
    margin: 15px 0;
    text-align: right;
    font-size: 12px;
    position: relative;
}
.login .identity div{
    width: 80px;
    float: right;
}
.login .identity input{
    width: 80px;
    outline: none;
    padding-left: 6px;
    border: 1px solid #ccc;
    font-size: 12px;
}
.login .identity div ul {
    border: 1px solid #ccc;
    border-top: none;
    position: absolute;
    width: 80px;
    background: #fff;
    margin-top: -1px;
}
.login .identity div ul li{
    padding-left: 6px;
}
.login .identity div ul li:hover {
    background: #2a97ff;
}

/* 忘记密码 */
/* .password{
    
} */
.password .email{
    width: 22%;
    min-width: 300px;
    margin: 10% auto;
    text-align: center;
    padding: 20px;
    box-shadow: 0 0 2px #ccc;
    height: 350px;
}
.password .email p{
    font-size: 18px;
    padding: 5px;
}
.password .email input{
    margin-top: 30px;
    width: 90%;
    padding: 10px;
}
.password .email button{
    margin-top: 50px;
    width: 90%;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: #3d67bc;
    color: #fff;
    font-size: 20px;
}
.password .email .back{
    background: #c2c2c2;
}


/* 房間 */
.room .selectList{
    background: #fff;
    /* padding: 0 10px; */
    font-size: 14px;
    width: 100%;
    max-height: 93px;
    overflow-y: scroll;
    position: absolute;
    box-shadow: 2px 2px 5px #ccc;
    color:#555;
    z-index: 10;
}
.room .selectList li {
    padding: 6px;
}
.room .selectList li:hover {
    background: #e6f7ff;
}