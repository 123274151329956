* {

    padding: 0;
    margin: 0;
    /* text-align: initial; */
}

.jButton {
    float: right;
    padding: 8px 12px;
    color: #fff;
    background-color: #3c66bd;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-right: 12px;
    margin-bottom: 10px;
}

.sj_panel {
    border: 1px solid #ebeef5;
    background-color: #fff;
    color: #303133;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.sj_panel_search {
    height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #eee;
}

.sj_panel_search input {
    font-size: 14px;
    width: 75%;
}

.sj_panel_search span {
    color: #408ceb;
    margin-left: 15px;
    cursor: pointer;
}


.sj_panel_users {
    padding: 10px 0;
    height: 250px;
    overflow: auto;
}

.sj_panel_users::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 6px;
}

/*定义滚动条轨道 内阴影+圆角*/
.sj_panel_users::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eee;
}

/*定义滑块 内阴影+圆角*/
.sj_panel_users::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: rgba(0, 0, 0, 0.1);
}

.sj_panel_user {
    width: 91%;
    margin: 0 auto 15px;
    font-size: 15px;
    display: flex;
    align-items: center;
    color: #2d2d2d;
    cursor: pointer;
}

.sj_panel_user input {
    margin-top: 2px;
}

.sj_panel_user span {
    margin-left: 10px;
    white-space: nowrap;
    width: 115px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
}