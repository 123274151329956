body {
    /* background-color: #efefef; */
    height: auto;
    margin: 0;
    padding: 0;
}

/* 頭部 */
.sc-head {
    background-color: white;
    height: 40px;
    width: 100%;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
}
/* 内容 */
.sc_content{
    background-color: white;
    margin-top: 2px;
    margin-bottom: 50px;
}
.sc_imgs{
    display: inline-block;
    vertical-align: middle;
}
.sc_imgs img{
    width: 55px;
    margin: 10px;
}
.sc_infos{
    display: inline-block;
    font-size: 18px;
    width: 75%;
    height: 41px;
    border-bottom: 1px solid #f1eeee;
}
.sc_infos button{
    position: absolute;
    right: 10px;
    background-color: #3f96fd;
    width: 70px;
    height: 23px;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 10px;
}
/* 底部 */
.sc_bottom{
    position: fixed;
    bottom:0px;
    width:100%;  
}
.sc_bottom button{
    border: none;
    outline: none;
    height: 45px;
    line-height: 45px;
    bottom: 0px;
    width: 100%;
    text-align: center;
    background-color: #29cc89;
    color: white;
    font-size: 18px;
}