* {

    padding: 0;
    margin: 0;
    /* text-align: initial; */
}

.pb_head {
    font-size: 28px;
    border-bottom: 1px solid #3c66bd;
    padding: 30px 0px 10px 0px;
    margin: 0 30px;
    /* margin: 0px 500px 0px 30px; */
}

.hp_main_box {
    overflow:hidden;
}

.pb_head p {
    float: right;
}

.p_page {
    height: 100%;
    /* background: #f7fbff; */
    background: #e6eaf0;
}

.p_nav {
    font-size: 18px;
    float: left;
    width: 230px;
    /* background: #f7fbff; */
    background: #fff;
    margin: 20px 0px 0px 20px;
    border-radius: 5px;
    height: 900px;
    /* padding: 20px 0 0 0; */
}

.p_nav img{
    position: relative;
    left: -5px;
    top: 8px;
    width: 28px;
}
.p_nav div {
    cursor: pointer;
    text-align: center;
    height: 55px;
    line-height: 55px;
    /* margin: 10px 0 0 0; */
    /* border-bottom: 1px solid #c1c1c1; */
    /* border-right: 1px solid; */
    position: relative;
    color: #777777;
    /* border-radius: 5px 5px 0 0; */
}

.p_nav .divhover {
    background: #3d67bc;
    color: #ffffff;
}

.p_bjshow{
  /* border-left: 1px solid #c4c4c4; */
  float: left;
  width: calc(100% - 270px);
  height: 100%;
  /* background: #ebf1f9; */
}

.p_table {
    float: left;
    width: calc(100% - 40px);
    min-height:90%;
    padding-bottom:20px;
    margin: 20px;
    background: #fff;
    border-radius: 5px;
}

.p_table .p_table_div{
    display: none;
}

.p_table_block {
    overflow: hidden;
    display: block !important;
    position: relative;
}

.p_table_block{
    display: block;
}


/*個人中心*/

.hp_main {
    /* width: 1200px; */
    height: auto;
}
.hp_sp {
    font-size: 35px;
    margin-left: 69px;
    margin-top: 69px;
}
.hp_My{
    color:#a3a3a3;
    font-size: 24px;
}
.hp_xian {
    background-color: rgb(61, 77, 166);
    position: absolute;
    width: 1053px;
    height: 3px;
    margin-top: 20px;
    z-index: 7;
    margin-left: 69px;
    margin-bottom: 30px;
}

.hp_big {
    overflow: hidden;
    /* height: 1060px; */
    height:auto;
}

.hp_tuwen {
    width: 305px;
    height: 240px;
    margin-left: 30px;
    margin-top: 20px;
    float: left;
    border-width: 1.5px;
    border-color: rgb(232, 232, 232);
    border-style: solid;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 3px 5px 0px rgba(191, 191, 191, 0.3);
}

.hp_notdata {
    width: 640px;
    height: 285px;
    margin: 80px auto;
}

.hp_notdata_o {
    width: 493px;
    height: 389px;
    margin: 10px auto;
}

.hp_tuwen img {
    width: 302px;
    height: 165px;
}

.hp_span {
    color: #000000;
    font-size: 18px;
    width: 302px;
    /* height: 65px; */
    text-align: center;
    padding:5px;
    text-align: left;
    text-indent: 1em;
}

.hp_span div {
    height: 24px;
    overflow: hidden;
    margin-bottom: 5px;    
}

.hp_shousuo {
    border-width: 1.5px;
    border-color: rgb(232, 232, 232);
    border-style: solid;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    width: 121px;
    height: 37px;
    z-index: 8;
    float: right;
    margin-right: 80px;
    margin-top: 35px;
    margin-bottom: 15px;
    /* text-align: center; */
    line-height: 37px;
}

.hp_shousuohp_span {
    margin-left: 13px;
}

.hp_shousuo .hp_san_shang {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #3d84a6;
    margin-top: -22px;
    margin-left: 53px;
}

.hp_san_xia {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #3d84a6;
    margin-top: -22px;
    margin-left: 88px;
}


/*通知中心*/

.content_bt {
    margin: 40px 0 0 70px;
    font-weight: 700;
    font-size: 32px;
}

.content_ {
    border-left: none;
    border-right: none;
    margin: 26px 30px 0 30px;
    font-size: 20px;
}

.content_news {
    height: 43px;
    border-bottom: 2px solid #e3e6eb;
    line-height: 43px;
}

.content_news:hover {
     background-color: #fafafa;
     cursor: pointer;
}

.content_newss {
    height: 43px;
    width: 1503px;
    line-height: 43px;
}

.content_photo {
    margin-top: 14px;
    width: 22px;
    height: 17px;
    background: url(/img/youjian.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    float: left;
    margin-left: 32px;
}

.content_news>span {
    width:100px;
    font-size: 18px;
    float: left;
    margin-left: 20px;
    height:40px;
    overflow:hidden;
}
.content_text {
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 18px;
    text-indent: 20px;
}

.content_button {
    font-size: 21px;
    height: 47px;
    width: 159px;
    background-color: #a7c5e2;
    text-align: center;
    color: azure;
    line-height: 25px;
    border-radius: 5px;
    border: none;
    display:block;
    margin: 20px auto;
}


/*資料管理*/

.my_bt {
    width: 1000px;
    border-bottom: 2px solid #8fa28f;
    height: 50px;
    font-size: 35px;
}
input{
    font-size: 18px;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
}

.my_all {
    width: 1000px;
    text-align: justify;
}

.my_name {
    margin-top: 20px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
}
.my_sf{
    display: flex;
    width: 50%;
    line-height: 36px;
}
.my_sex{
    margin-top: 12px;
}
#sf{
    font-size: 18px;
    width: 75px;
    height: 36px;
}

.my_diqu {
    font-size: 18px;
    margin-left: 589px;
    margin-top: -58px;
}

.my_all .my_name span {
    min-width: 162px;
    display: inline-block;
    text-align: right;
    text-align-last: right;
    line-height: 36px;
}

.my_area {
    font-size: 18px;
    width: 76.5%;
    height: 190px;
    vertical-align: top;
    padding-left: 8px;
    line-height: 30px;
    color:#000;
}

.my_text {
    width: 268px;
    height: 36px;
    padding-left: 8px;
}
.anniu{
    margin: 50px auto;
}
#my_anniu {
    color: white;
    width: 187px;
    height: 46px;
    border-radius: 5px;
    text-align: center;
    background: #76B8DE;
    border: none;
    display: block;
    margin: 0 auto;
    font-size: 16px;
    cursor: pointer;
}


/*課程管理*/

.p_course_head {
    font-size: 30px;
    border-bottom: 3px solid #3d4da6;
    padding: 30px 0px 10px 0px;
    margin: 0px 500px 0px 30px;
}

.p_course_modify {
    width: 1200px;
    float: left;
    display: none;
    margin-bottom: 100px
}

.p_course_input input {
    outline: 0;
}

.p_course_search {
    /*float: right;
    width: 75px;
    height: 31px;
    margin: 4px 5px 5px 5px;
    background-color: #76b8de;
    color: #ffffff;
    border-radius: 5px;
    padding: 0;
    text-align: center;
    line-height: 31px;
    font-size: 16px;*/
    /* float: right;
    width: 30px;
    height: 30px;
    font-size: 16px;
    position: relative;
    right: 30px;
    top:7px;
    background: url("/img/search.png");
    background-repeat: no-repeat */
    background: url(/img/sousuo.png);
    background-repeat: no-repeat;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 0px;
    height: 35px;
    width: 35px;
    cursor: pointer;
    float:right;
    display: none;
}

/* .p_course_all::after{
    content: '';
    display: block;
    visibility: hidden;
    zoom: 1;
    clear: both;
} */

.p_course_all {
    /*float: left;*/
    margin-bottom: 15px;
    padding-top: 35px
}

.p_course_all_div {
    width: 310px;
    height: 310px;
    border: 1px solid;
    border-radius: 5px;
    margin: 10px 0px 30px 25px;
    /* float: left; */
    display: inline-block;
    overflow: hidden;
    border: 1px solid #d8d8d8;
    font-size: 16px;
}

.p_course_all_img {
    width: 101%;
    height: 210px;
    margin:-1px;
    background-repeat: no-repeat;
    background-size: 310px 210px;
    background-color:#fff;
}
.p_course_all_img>img{
    width: 100%;
    height: 100%;
}

.p_course_modify img {
    
}

.p_course_all_name {
    width: 310px;
    height: 50px;
    /*text-align: center;*/
    padding: 0px 15px;
    /* display: table-cell; */
    vertical-align:middle;
    color: #555;
    font-size: 18px;
    overflow: hidden;
    line-height: 45px;
}

.p_course_all_go {
    width: 33.3%;
    height: 50px;
    border-top: 1px solid #e8e8e8;
    /*border-left: 1px solid #e8e8e8;*/
    float: left;
    text-align: center;
    background-color: #f6f6f6;
    line-height: 50px;
    cursor: pointer;
}
.p_course_all_go:hover{
    background-color: #e2e2e2;
}
.p_course_all_modify {
    width: 33.3%;
    height: 50px;
    border-top: 1px solid #e8e8e8;
    /*border-left: 1px solid #e8e8e8;*/
    float: left;
    text-align: center;
    background-color: #f6f6f6;
    line-height: 50px;
    cursor: pointer;
}
.p_course_all_modify:hover{
    background-color: #e2e2e2;
}
.p_course_all_de {
    width: 33.3%;
    height: 50px;
    border-top: 1px solid #e8e8e8;
    /*border-left: 1px solid #e8e8e8;*/
    float: left;
    text-align: center;
    background-color: #f6f6f6;
    line-height: 50px;
    cursor: pointer;
}
.p_course_all_de:hover{
    background-color: #e2e2e2;
}

.form {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    display: none;
    background: #eeeeee;
}


.form-window-cover {
    display: block;
    z-index: 1997;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    position: fixed;
}

.form-window {
    position: absolute;
    min-width: 450px;
    box-shadow: 1px 3px 11px #838383;
    left: 50%;
    top: 50%;
    /* padding-bottom: 35px; */
    background: #FFF;
    border-radius: 5px;
    overflow: hidden;
    z-index: 100;
    transform: translate(-50%, -50%);
}

.form-head {
    background: rgba(60, 94, 143, 1);
    line-height: 55px;
    height: 55px;
    text-align: center;
    position: relative;
}

.form-head .form-logo {
    position: absolute;
    width: 195px;
    height: 35px;
    left: 0px;
    color: #fff;
    cursor: pointer;
    line-height: 46px;
    font-size: 33px;
    top: 12px;
    /*background: url("logo.png") no-repeat;*/
    background-size: 80% 80%;
}

.form-head h3 {
    color: #FFF;
    font-weight: 100;
    margin: 0;
    text-align: center;
}

.form-head .f_cancel {
    position: absolute;
    width: 46px;
    height: 46px;
    right: 0px;
    color: #fff;
    cursor: pointer;
    line-height: 46px;
    font-size: 33px;
    top: 3px;
}

.form-main {
    box-sizing: border-box;
}

.form-content {
    /* height: 100px; */
    line-height: 45px;
    /* display: flex; */
    justify-content: center;
    align-items: center;
}

.form_delete-main {
    /* display: flex; */
    /* justify-content: center; */
    align-items: center;
    /* height: 35px; */
    margin: 20px 30px;
}

.form_delete-main div {
    font-size: 19px;
    font-weight: 500;
}

.form-btn {
    /* padding: 24px 0; */
    text-align: center;
    height: 85px;
    line-height: 85px;
    box-sizing: border-box;
}

.form-btn button {
    width: 110px;
    height: 42px;
    margin: 0 55px;
    border-radius: 7px;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
}

.form-confirm {
    background: #d62b2b;
    border: none;
    color: #FFF;
}

.form-cencal {
    color: #ffffff;
    background: #76b8de;
    border: none;
    text-align: center;
}


/*房間管理*/

.room_title {
    float: right;
    margin-top: 55px;
    color: #5b5b5b;
}

.room_hr {
    width: 100%;
    height: 2px;
    background-color: #3d4da6;
    float: right;
}

.room_select {
    width: 100%;
    height: 60px;
}

.room_select_right {
    width: 261px;
    height: 35px;
    float: right;
    position: relative;
    margin-top: 18px;
}

.room_select_right_input {
    width: 200px;
    height: 35px;
    line-height: 32px;
    padding: 0 12px;
    border: 1px solid #ccc;
    border-right: 0px;
    float: left;
    background: #f7f7f7;
    border-radius: 0;
    font-size: 16px;
}

.room_select_right_button {
    background: url("/img/sousuo.png");
    background-repeat: no-repeat;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 0px;
    height: 35px;
    width: 35px;
    cursor: pointer;
}

.room_class {
    margin-top:15px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.room_course {
    width: 310px;
    height: 323px;
    margin-left: 25px;
    margin-bottom: 30px;
    float: left;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #d8d8d8;
}

.room_course_pic {
    width: 100%;
    height: 209px;
}

.room_course_pic>img {
    width: 100%;
    height: 100%;
}

.room_course_title {
    width: 311px;
    height: 65px;
    float: left;
    border: 1px solid #f4f4f4;
}

.room_course_title div {
    color: #777;
    font-size: 18px;
    margin-top:5px;
    margin-left: 5px;
}

.room_course_function {
    width: 100%;
    height: 50px;
    float: left;
    line-height: 50px;
    cursor: pointer;
    font-size:16px;
}

.room_frist {
    width: 33.3%;
    height: 100%;
    border: 1px solid;
    float: left;
    text-align: center;
    background-color: #959595;
}

.room_course_afunction {
    width: 33.3%;
    height: 100%;
    border-top: 1px solid #e8e8e8;
    /* border-left: 1px solid #f6f6f6; */
    float: left;
    text-align: center;
    background-color: #f6f6f6;
}
.room_course_afunction:hover{
    background-color: #e2e2e2;
}
.show_room_page{
    margin-left: 30px;
    margin-bottom: 20px;
    margin-right: 30px;
    float: right;
}
.room_alertRevise_need{
    margin-top: 30px;
    width: 138px;
    float: left;
    padding: 5px;
  }
  .room_alertRevise_need > li{
    list-style: none;
    width: 100%;
    height: 40px;
    float: left;
    margin-bottom: 40px;
  }
  .room_alertRevise_need > li > span{
    float: right;
    font-size: 18px;
  } 
  .room_alertRevise_input{
    margin-top: 30px;
    width: 389px;
    float: left;
    margin-left: 10px;
  }
  .room_alertRevise_input > li {
    list-style: none;
    float: left;
    height: 40px;
    margin-bottom: 40px;
  }
  .room_alertRevise_input > li > input{
    width: 390px;
    height: 40px;
    font-size: 18px;
  }
  .room_alertRevise_update{
    width: 136px;
    height: 46px;
    cursor: pointer;
    background-color: #76b8de;
    float: right;
    text-align: center;
    border-radius: 10px;
    color: #ffffff;
    margin-right: 20px;
    line-height: 46px;
    font-size: 18px;
  }
/* 通知中心 */
.pop{
    width: 835px;
    height: 640px;
    border: 1px  solid #e3e6eb;
    box-shadow: 0 0 10px #888888;
}
.pop_head{
    width: 100%;
    height: 56px;
    background-color: #45699a;
    line-height: 56px;
}
.pop_bt{
    font-weight: 700;
    font-size: 22px;
    color: rgb(255, 255, 255);
    margin-left: 24px;
    float: left;
}
.pop_photo{
    width: 50px;
    height: 50px;
    background: url(/img/cancel.png);
    background-repeat: no-repeat;
    float:right;
    margin-right:15px; 
}
.pop_text{
    margin-left: 20px;
    margin-top:20px;
    font-size: 18px;
}
.pop_tc{
    border: 1px solid #9b9b9c;
    margin: 25px 40px 0 40px;
    font-size: 18px;
    min-height: 200px;
    /* width: 600px; */
}

.pop_button{
    font-size: 16px;
    height: 36px;
    width: 127px;
    background-color: #d62b2b;
    text-align: center;
    color: azure; 
    border-radius:5px;
    border:none;
    margin-top: 45px;
    float: right;
    margin:20px 30px 20px 0;
 }

 /* 好友分組 */
 .friend_search {
    width: 261px;
    height: 35px;
    float: right;
    position: relative;
}

.friend_searchbox {
    width: 200px;
    height: 35px;
    line-height: 32px;
    padding: 0 12px;
    border: 1px solid #ccc;
    border-right: 0px;
    float: left;
    background: #f7f7f7;
    border-radius: 0;
    font-size: 16px;
}

.friend_searchbtn {
    background: url(/img/sousuo.png);
    background-repeat: no-repeat;
    border-right: 1px solid #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 0px;
    height: 35px;
    width: 35px;
    cursor: pointer;
}
.friend_allbtn{
    float: right;
    margin-right: 20px;
}
.friend_Btn {
    color: #fff;
    cursor: pointer;
    text-align: center;
    width: 80px;
    margin: 0 10px;
    background: #5190fd;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    float: right;
    font-size: 16px;
  }
  .friend_addgroupBtn{
    color: #fff;
    cursor: pointer;
    text-align: center;
    width: 80px;
    margin: 0 10px;
    background: #5190fd;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    float: right;
    font-size: 16px;
  }

 .friend_groups {
    height: 712px;
    width: 14%;
    background-color: #f5f5f5;
    float: left;
    margin: 2% 2%;
    border-radius: 8px 8px 0 0;
}

.friend_addgroups {
    background-color: #3d67bc;
    width: 100%;
    height: 41px;
    line-height: 41px;
    margin-bottom: 15px;
    border-radius: 8px 8px 0 0;
}

.friend_addgroups>span {
    font-size: 18px;
    margin-left: 12px;
    color: azure;
    float: left;
}

.friend_allgroups {
    float: left;
    font-size: 18px;
    color: azure;
}

.friend_groupsdelete {
    background: url(/img/jian.png);
    float: right;
    width: 17px;
    height: 17px;
    margin: 11px 9px 0 0;
}

.friend_groudsbody {
    width: 220px;
    height: 200px;
}

.friend_groupslist {
    width: 100%;
    height: 35px;
    border: none;
    border-bottom: 1px solid #ccc;
}

.friend_addgroupsspan{
    width: 45%;
    height: 35px;
    float: left;
}
.friend_addgroupsspan>span {
    font-size: 18px;
    float: left;
    margin-left: 15px;
    line-height: 35px;
}

.friend_groupspeople {
    font-size: 18px;
    line-height: 35px;
    margin-right: 15px;
    float: left;
}

.friend_people {
    width: 100%;
    border: 2px solid #ebebeb;
    border-top: none;
    margin-top:12px;
}

.listing {
    position: relative;
    border-collapse: collapse;
    border: 0px solid #eeeeee;
    border-bottom: none;
    white-space: nowrap;
    table-layout: fixed;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    display: block;
    width: 70%;
    float: left;
    border: 1px solid #eeeeee;
    border-top: 4px solid #e6eaf0;
    margin: 2% 4%;
  }

  .friend_head{
    background-color: rgba(247, 247, 247, 1);
    height: 52px;
    line-height: 52px;
    text-align: center;
    /* border-bottom: 2px solid #e7eff9; */
    border: none;
    color: black;
    font-weight: 600;
    border-radius: 5px 5px 0 0;
    width: 100%;
    /* padding: 0px; */
    /* margin: 0px; */
    /* border: 1px solid #aeb2b7; */
    /* border-bottom: 0px; */
    /* border-top: 1px solid #aeb2b7; */
    border-bottom: 2px solid #eeeeee;
  }

  .friend_head li>div {
    float: left;
    width: calc(100% / 6);
    text-indent: 1em;
    text-align: center;
  }

  .friend_head li>div:last-child {
      border-right: none;
  }
  .friend_tbody {
    /* height: 640px;  */
    background-color: #F7F7F7;
    overflow: auto;
    /* height: auto; */
    float: left;
    width: 100%;

  }
  
  .friend_tbody li {
    float: left;
    width: 100%;
    height: 64px;
    line-height: 64px;
    border-bottom: 1px solid #eeeeee;
  }
  
  /* .room_page{
    margin:30px 30px 30px 22px;
    float: right;
  } */
  
  .friend_tbody li:nth-child(odd) {
    background: #FFFFFF;
  }
  
  .friend_tbody li:nth-child(even) {
    background: #F7F7F7;
  }
  .friend_tbody li:nth-child(even)>div {
    border-right: 2px solid #eeeeee00;
  }
  
  .friend_tbody li>div {
    float: left;
    text-align: center;
    width: calc(100% / 9);
    position: relative;
    font-size: 18px;
    text-overflow: ellipsis;
    color: #000000;
    white-space: nowrap;
    height: 64px;
    overflow: hidden;
    border-right: 2px solid #eeeeee;
    text-indent: 0.5em;
    padding: 0 5px;
    float: left;
    width: calc(100% / 6);
    border-right: 2px solid #eeeeee;
    text-indent: 1em;
    text-align: center;
  }
  
  .friend_deleteBtn {
    color: #fff;
    cursor: pointer;
    text-align: center;
    width: 90px;
    margin: 0 5px;
    /* background: #5190fd; */
    color: #e37474;
    display: inline-block;
    /* height: 40px; */
    line-height: 40px;
    border-radius: 5px;
    margin-right: 25px;
  }
  /* .friend_tbody li>div:last-child {
    width: 200px !important;
    float: left;
    font-size:16px;
  }
  
  .friend_tbody li>div:last-child {
    border-right: none;
  } */

.friend_tbody li>div,
.friend_head li>div {
  width: calc(100% / 4.5) !important;
}

.friend_tbody li .friend_first,
.friend_head li .friend_first {
  width: 10% !important;
}

.friend_tbody li .friend_second,
.friend_head li .friend_second {
  width: 15% !important;
}

.friend_tbody li .friend_third,
.friend_head li .friend_third {
  width: 25% !important;
}

.friend_tbody li:nth-child(odd) {
    background: #FFFFFF;
  }
  
.friend_tbody li:nth-child(even) {
    background: #F7F7F7;
  }

.friend_tbody li:nth-child(even)>div {
    border-right: 2px solid #eeeeee00;
  }
.form_friendgroup{
    line-height: 35px;
display: flex;
justify-content: center;
align-items: center;
margin-top: 15px;
}
.form_friendgroupmain{
    margin: 20px 30px;
    line-height: 22px;
}
.form_friendgroupspan {
    float: left;
    font-size: 18px;
}
.form_friendgroupadd{
    font-size: 19px;
    font-weight: 500;
    float: left;
    margin-left: 15px;
}
.form_friendgroupadd > input{
    border-radius: 0px;
}
.friend_photodelete {
    float: right;
    width: 13px;
    height: 13px;
    margin-right: 10px;
    line-height: 36px;
}
 /*分頁box*/

 .newscontent_page{
    padding:30px 30px 0 30px;
    float: right;
 }

 #my_school{
    width: 300px;
    border: 1px solid #dcdcdc;
    font-size: 18px;
    padding-left: 2px;
 }

 .room_insert {
    width: 82px;
    height: 35px;
    margin: 5px;
    background-color: #5280db;
    color: #ffffff;
    border-radius: 5px;
    padding: 0;
    text-align: center;
    line-height: 33px;
    float: right;
    margin-right: 30px;
    margin-top: 17px;
    font-size: 16px;
    cursor: pointer;
}
 /*分頁box*/

 .scpage{
    padding:30px 0 1px 30px;
    float: right;
    margin-right: 10%;
 }
 .shoucpage{
    padding:30px 0 1px 30px;
    float: right;
    margin-right: 10%;
 }
 .dypage{
    padding:30px 0 1px 30px;
    float: right;
    margin-right: 10%; 
 }
 .p_course_paging{
    float: right !important;
    margin-right: 30px;
    width: auto !important;
 }
 .p_course_searchI{
    width: 200px;
    height: 35px;
    line-height: 32px;
    padding: 0 12px;
    border: 1px solid #ccc;
    border-right: 0px;
    float: left;
    background: #f7f7f7;
    border-radius: 0;
    font-size: 16px;
    float:right;
    display: none;
 }
 .p_course_input{
    width: 261px;
    height: 35px;
    float: right;
    /* position: relative; */
    margin-top: 18px;
    width:100%;
 }
 .from-select-student-content{
    height: 180px;
    line-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .from-select-student-information{
    padding: 0 80px;
  }
  .from_student_information{
    width: 100%;
    height: 35px;
    display: inline-block;
    margin-bottom: 30px;
    line-height: 35px;
    font-size: 18px;
  }
  .need_namef{
    text-align: justify;
    text-align-last: justify;
    float: left;
    width: 75px;
  }
  .val-input{
    width: 225px;
    height: 28px;
    float: left;
    margin: 0 20px;
  }
  .need_names{
    float: left;
  }
  .need_namet{
    float: left;
  }
  .radio_label{
    float: left;
    margin-left: 20px;
  }
  .radio_label > div{
    float: left;
  }
  .val-input-M{
    width: 240px;
    height: 28px;
    float: left;
    margin: 0 20px;
  }
  .val-input-L{
    width: 300px;
    height: 28px;
    float: left;
    margin: 0 20px;
  }
  .val-input-S{
    width: 145px;
    height: 28px;
    float: left;
    margin: 0 20px;
  }
  .from-student-btn{
      height: 85px;
      line-height: 42px;
      margin-top: 30px;
      margin-left: 50px;
      color: #ffffff;
      float: right;
    }
  .form-student-cencal {
      width: 130px;
      height: 42px;
      margin: 0 5px;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      font-size: 18px;
      background-color: #b3b3b3;
      display: inline-block;
      text-align: center;
    }
    .form-student-confirm{
      width: 170px;
      height: 42px;
      margin: 0 5px;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      font-size: 18px;
      background-color: #5c98d6;
      display: inline-block;
      text-align: center;
    }

    /* 添加学生 */
.AddStudent {
    position: relative;
}

.AddStudent button {
    cursor: pointer;
}

.student .downloadSpan {
    float: right;
    font-size: 16px;
    color: #fff;
    padding: 8px 12px;
    background-color: #3c66bd;
    margin-right: 12px;
    border-radius: 5px;
    cursor: pointer;
}

.student input {
    position: absolute;
    right: 107px;
    padding: 5px 0px;
    color: #fff;
    background-color: #3c66bd;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-right: 12px;
    width: 88px;
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer;
}

.student button {
    float: right;
    padding: 8px 12px;
    color: #fff;
    background-color: #3c66bd;
    font-size: 16px;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-right: 12px;
}
/* 查询 */
.search {
    padding: 18px 30px;
}

.search input {
    padding: 8px 10px;
    font-size: 14px;
}

.search button {
    border: none;
    outline: none;
    padding: 8px 12px;
    margin-left: 15px;
    border-radius: 5px;
    color: #fff;
    background-color: #2268BC;
}

.addStudentModal {
    width: 540px;
    background: #fff;
    border-radius: 5px;
    position: relative;
    border: none;
    outline: none;
}

/* 
@media screen and (max-width:1400px) {
    .addStudentModal {
        width: 80%;
    }
} */

.addStudentModal div {
    position: relative;
}

.addStudentModal div h2 {
    background-color: #3D67BC;
    color: #fff;
    text-align: center;
    padding: 10px 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 16px;
}

.addStudentModal div .sspan {
    position: absolute;
    /* font-size: 30px; */
    color: #fff;
    right: 20px;
    top: 10px;
    cursor: pointer;
}

.addStudentModal .sspan img {
    width: 20px !important;
}

.addStudentModal ul {
    width: 95%;
    margin: 10px auto;
}

.addStudentModal ul li {
    padding: 15px;
    position: relative;
}

.addStudentModal ul li .sspan {
    display: inline-block;
    text-align: right;
    width: 80px;
}

.addStudentModal ul li .inputs {
    width: 80%;
    height: 43px;
    border: 1px solid #bbbbbb;
    border-radius: 5px;
    font-size: 18px;
}

.addStudentModal ul li input {
    padding: 10px;
}

.eyeClass {
    position: absolute;
    right: 44px;
    width: 23px;
    top: 26px;
    cursor: pointer;
}

.addStudentModal .submit {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.addStudentModal .submit button {
    padding: 5px 25px;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 20px;
    cursor: pointer;
    margin: 0 20px 0px 0;
}
