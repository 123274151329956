body {
    /* background-color: #efefef; */
    height: auto;
    margin: 0;
    padding: 0;
}

/* 頭部 */
.yl-head {
    background-color: white;
    height: 40px;
    width: 100%;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
}

.yl-head span:nth-child(1) {
    float: left;
}

.yl-head img {
    height: 20px;
    margin: 10px 5px;
}

.yl-head span:nth-child(2) {
    display: inline-block;
}

.yl-content {
    background-color: white;
    width: 100%;
    margin-top: 10px;
}
.yl-cimg{width: 60px;}
.ming span:nth-child(1) {
    font-size: 18px;
}
.yl-left{margin:30px 10px 10px 0px;float: left;}
.yl-right{width: 60%;display: inline-block;margin-top: 38px;}
.yl-rspan{font-size: 18px;}
.yl-clock {
    font-size: 15px;
    color: #747474;
    display: block;
}

.yl-img {
    margin: 10 auto;
    width: 100%;
    margin-bottom: 30px;
}

.ly-bar {
    background-color: white;
    margin-top: 20px;
    font-size: 18px;
    height: 250px;
}
.ly-wenzi{
    margin-left: 20px;
}
.ly-span1 {
    display: inline-block;
    margin-top: 10px;
}

.ly-span2 {
    color: #747474;
    display: block;
}
.ly-bottom{position: fixed;margin:0 4%;bottom:10px;width:92%;text-align: center;}
.ly-bottom img{width:43%;}
.ly-bimg{margin-left: 10%;}