
.chapter {text-align: left;background-color: #f2f2f2; width: 400px; height: 300px; padding: 10px 15px; border-radius: 8px; overflow: auto; font-size: 16px; }
        .chapter_upload { height: 50px; margin-top: 8px; position: relative; }
        .chapter_upload_move { position: relative; background-color: #FFF; position: absolute; width: 100%; top: 0px; left: 0px; border: 1px solid #eee; border-radius: 5px;transition: width 2s; -moz-transition: width 2s;-webkit-transition: width 2s;-o-transition: width 2s; }
        .chapter_upload_o        { width:100%; height:100%; position:relative; z-index:1; }
        .chapter_upload_t       { background-color:#E3F3FF; position:absolute; width:0%; height:100%; top:0px; left:0px; }
        .chapter_upload_l { width: 50px; height: 50px; border-right: 1px solid #eee; float: left; }
        .chapter_upload_l_i { background-image: url('../img/icon.png'); background-position: 3px -165px; width: 30px; height: 30px; margin: 10px auto 0 auto; }
        .chapter_upload_n { height: 50px; line-height: 50px; text-indent: 10px; text-decoration:none;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;}
        .chapter_upload_ic { height: 30px; float: right; }
        .chapter_upload_ic_l1 { height: 50px; border-right: 1px solid #eee; float: left; }
        .chapter_upload_ic_l { width: 50px; height: 50px; float: left; border-right: 1px solid #eee; }
        .chapter_upload_ic_l1 div { width: 30px; height: 35px; background: url('../img/uploading-progress.gif') no-repeat left top; margin: 10px auto 0 auto;background-size: 100%; }
        .chapter_upload_ic_l div { width: 30px; height: 35px; background: url('../img/icon.png'); margin: 10px auto 0 auto; }
        .chapter_upload_ic_r { width: 40px; height: 40px; float: left; margin-left: 5px; }
        .chapter_upload_ic_r div { width: 30px; height: 30px; background-image: url('../img/icon.png'); background-position: -39px -163px; margin: 10px auto 0 auto; }
        .chapter_add { width: 100%; height: 32px; margin-top: 15px; }
        .chapter_add_l { margin-left: 5px; line-height: 22px; width: 30px; height: 30px; border-right: 1px solid #eee; float: left; border: 1px solid #AAA; color: #AAA; border-radius: 50%; font-size: 25px; font-weight: bold; text-align: center; }
        .chapter_add_l_i { background-image: url('../img/icon.png'); background-position: 3px -165px; width: 30px; height: 30px; margin: 10px auto 0 auto; }
        .chapter_add_r { font-size: 18px; height: 40px; line-height: 30px; text-indent: 10px; color: #AAA; }
        .chapter_add_input { display: none; }