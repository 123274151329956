.chapter_select{
    width: 80%;
    margin: 0 auto;
}

.chapter_select h2{
    padding: 0 0 15px 30px;
    /* background: #eee; */
    border-radius: 10px;
}

.allFlex{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}


.chapter_a{
    display:block;
    width:31%;
    margin-bottom:5%;
    text-decoration:none;
    text-decoration-color:#fff;  
    min-width: 310px;
    margin-right: 2%;
    text-align: center;
}

.chapter_a:active{
    color:#fff
}
.chapter_a:hover{
    color:#fff;
}

.chapter_title{
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.5rem;
    height: 30px;
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;
    line-height: 1.33;
    text-align: center;
    margin-bottom:0.35em;
}

.chapter_info{
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.875rem;
    height: 60px;
    overflow: hidden;
    word-wrap: break-word;
    overflow-wrap: break-word;
    line-height: 1.46429em;
    text-align: center;
}
